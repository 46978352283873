import React, { Suspense, lazy } from "react";
import "../Homepage/home.css";
import Navbar from "../../components/Landingpage/Navbar/Navbar";
import Hero from "../../components/Landingpage/Hero/Hero";
import Features from "../../components/Landingpage/Features/Features";
import About from "../../components/Landingpage/About/About";
import Modern from "../../components/Landingpage/Modern/Modern";
import How from "../../components/Landingpage/HowItWork/How";
import Footer from "../../components/Landingpage/Footer/Footer";

const VideoSection = lazy(() =>
  import("../../components/More/VideoSection/VideoSection")
);
// const Testimonial = lazy(() =>
//   import("../../components/Landingpage/Testimonial/Testimonial")
// );
const Faq = lazy(() => import("../../components/Landingpage/Faq/Faq"));
const Appview = lazy(() =>
  import("../../components/Landingpage/AppInterfaces/AppInterface")
);
const Download = lazy(() =>
  import("../../components/Landingpage/DownloadApp/Download")
);
const NewsLetter = lazy(() =>
  import("../../components/More/Newsletter/NewsLetter")
);

const Home = () => {
  return (
    <div className="page_wrapper">
      <Navbar />
      <Hero />
      <Features />
      <About />
      <Modern />
      <How />
      <Suspense fallback={<div>Loading Video Section...</div>}>
        <VideoSection />
      </Suspense>
      {/* <Suspense fallback={<div>Loading Testimonials...</div>}>
        <Testimonial />
      </Suspense> */}
      <Suspense fallback={<div>Loading FAQ...</div>}>
        <div id="faq">
          <Faq />
        </div>
      </Suspense>
      <Suspense fallback={<div>Loading App View...</div>}>
        <Appview />
      </Suspense>
      <Suspense fallback={<div>Loading Download Section...</div>}>
        <Download />
      </Suspense>
      <Suspense fallback={<div>Loading Newsletter...</div>}>
        <NewsLetter />
      </Suspense>
      <Footer />
    </div>
  );
};

export default Home;
