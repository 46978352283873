import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../Footer/footer.css";
import Animline from "../Animline/Animline";
import Preloader from "../../More/Loader/Preloader";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  const handleScroll = () => {
    const scrollPosition = window.scrollY || window.pageYOffset;
    setIsVisible(scrollPosition > 750);
  };

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavClick = (e, path) => {
    if (path.startsWith("#")) {
      e.preventDefault();
      if (location.pathname !== "/") {
        window.location.href = `/#${path.substring(1)}`;
      } else {
        scrollToSection(path);
      }
    } else {
      setShowLoader(true);
    }
  };

  useEffect(() => {
    setShowLoader(false);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <!-- Footer-Section start -->
    <footer>
      {showLoader && <Preloader />}
      <div className="top_footer" id="contact">
        {/* <!-- animation line --> */}
        <Animline />
        {/* <!-- container start --> */}
        <div className="container">
          {/* <!-- row start --> */}
          <div className="row">
            {/* <!-- footer link 1 --> */}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="abt_side">
                <a href="/">
                  <img
                    src="/enc/images/logo_3.png"
                    alt="img"
                    width="180px"
                    height="80px"
                  />
                </a>

                <ul>
                  <li>
                    <a href="mailto:support@triphoppa.com">
                      support@triphoppa.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+254746389580">+254 746 389-580</a>
                  </li>
                </ul>
                <ul className="social_media">
                  <li>
                    <a
                      href="www.facebook.com/triphoppa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebookF size={20} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/TRIPHOPPA1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter size={20} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/triphoppa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram size={20} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/triphoppa/posts/?feedView=all"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedinIn size={20} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <!-- footer link 2 --> */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="links">
                <h3>Useful Links</h3>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/">About us</a>
                  </li>
                  <li>
                    <a href="/">Services</a>
                  </li>
                  <li>
                    <a href="/">Blog</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <!-- footer link 3 --> */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="links">
                <h3>Help & Support</h3>
                <ul>
                  <li>
                    <a href="#faq" onClick={(e) => handleNavClick(e, "#faq")}>
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us">Support</a>
                  </li>
                  <li>
                    <a
                      href="#how_it_work"
                      onClick={(e) => handleNavClick(e, "#how_it_work")}
                    >
                      How It Works
                    </a>
                  </li>
                  <li>
                    <a href="/terms-of-use">Terms Of Use</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <!-- footer link 4 --> */}
            <div className="col-lg-2 col-md-6 col-12">
              <div className="try_out">
                <h3>Download Mobile App</h3>
                <ul className="app_btn">
                  <li>
                    <a
                      href="https://testflight.apple.com/join/X7E3qz6R"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/enc/images/appstore_blue.png" alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/apps/internaltest/4701682408872991350"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/enc/images/googleplay_blue.png" alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- row end --> */}
        </div>
        {/* <!-- container end --> */}
      </div>

      {/* <!-- last footer --> */}
      <div className="bottom_footer">
        {/* <!-- container start --> */}
        <div className="container">
          {/* <!-- row start --> */}
          <div className="row">
            <div className="col-md-6">
              <p>© Copyrights {currentYear}. All rights reserved.</p>
            </div>
            <div className="col-md-6">
              <p className="developer_text">Encounter Global</p>
            </div>
          </div>
          {/* <!-- row end --> */}
        </div>
        {/* <!-- container end --> */}
      </div>

      {/* <!-- go top button --> */}
      <div
        className="go_top"
        onClick={scrollToTop}
        style={{ opacity: isVisible ? 1 : 0, transition: "opacity 1s" }}
      >
        <span>
          <img src="/enc/images/go_top.png" alt="img" />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
