import React, { useState } from "react";
import "../Hero/hero.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { GiSteeringWheel } from "react-icons/gi";

const Herocta = () => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordInputType = showPassword ? "text" : "password";

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className="hero-area-v-2 p-0 dark-overlay-3">
      <video autoPlay loop muted playsInline id="myVideo" width="100">
        <source src="/assets/bg-video-2.mp4" type="video/mp4" /> Your browser
        does not support HTML5 video.
      </video>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="hero-area-wrap">
              <div className="hero-area-left">
                <h1 className="hero-title-v-2">
                  <span className="hero-title-bg">
                    {" "}
                    Earn.{" "}
                    <span className="hero-title-accent-v-2">Connect.</span>
                    <span> Save money. </span>
                  </span>
                </h1>
                <p className="hero-text">
                  {" "}
                  Partner with us to empower eco-friendly rides, convenient
                  carpool, happy commuters and cost-effective ride shares.{" "}
                </p>
                <div className="download-buttons">
                  <a
                    href="https://testflight.apple.com/join/X7E3qz6R"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="download-apple-btn"
                  >
                    <img src="/assets/images/download-1.webp" alt="img" />
                  </a>
                  <a
                    href="https://play.google.com/apps/internaltest/4701682408872991350"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="download-android-btn"
                  >
                    <img src="/assets/images/download-2.webp" alt="img" />
                  </a>
                </div>
              </div>
              <div className="hero-area-right">
                <div className="combine-form">
                  <nav className="navigation">
                    <div className="nav nav-tabs form-tab" role="tablist">
                      <button
                        className="nav-link"
                        id="nav-ride-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-ride"
                        type="button"
                        role="tab"
                        aria-controls="nav-ride"
                        aria-selected="false"
                      >
                        <FontAwesomeIcon
                          icon={faCar}
                          style={{ fontSize: "16px" }}
                        />{" "}
                        Take a Ride{" "}
                      </button>
                      <button
                        className="nav-link active"
                        id="nav-drive-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-drive"
                        type="button"
                        role="tab"
                        aria-controls="nav-drive"
                        aria-selected="true"
                      >
                        <GiSteeringWheel size={20} /> Apply to Drive{" "}
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade"
                      id="nav-ride"
                      role="tabpanel"
                      aria-labelledby="nav-ride-tab"
                    >
                      <form action="#" className="form1">
                        <h2 className="form-title">
                          Get member exclusive rewards
                        </h2>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              id="firstName"
                              placeholder="First Name"
                              disabled
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              id="lastName"
                              placeholder="Last Name"
                              disabled
                            />
                          </div>
                          <div className="form-group col-12">
                            <input
                              type="number"
                              className="form-control"
                              name="number"
                              id="number"
                              placeholder="Phone Number"
                              disabled
                            />
                          </div>
                          <div className="form-group col-12">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Email Address"
                              disabled
                            />
                          </div>
                          <div className="form-group col-12">
                            <input
                              type="text"
                              className="form-control"
                              name="location"
                              id="location"
                              placeholder="City"
                              disabled
                            />
                          </div>
                          <div className="form-group col-12">
                            <input
                              className="form-control"
                              name="password"
                              id="password"
                              placeholder="Password"
                              type={passwordInputType}
                              disabled
                            />
                            <span
                              id="toggle-password"
                              className="toggle-password"
                              onClick={togglePasswordVisibility}
                              style={{ cursor: "pointer" }}
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="col-12 form-group mt-3">
                            <label for="agree">
                              By proceeding, you agree to the{" "}
                              <a href="/terms-of-use">Terms and Conditions</a>{" "}
                              and <a href="/privacy-policy">Privacy Policy</a>
                            </label>
                          </div>
                          <div className="form-btn col-12">
                            <a href="https://forms.gle/ZSHEKzHcbfxtA2aNA">
                              <button
                                className="form-button button button-dark big"
                                style={{
                                  background: "#962A8D",
                                  color: "#fff",
                                  marginTop: "30px",
                                }}
                              >
                                {" "}
                                {/* Sign up to Ride{" "} */}
                                Join Waitlist
                              </button>
                            </a>
                          </div>
                        </div>
                        <p className="form-messages mb-0 mt-3"></p>
                      </form>
                    </div>
                    <div
                      className="tab-pane fade show active"
                      id="nav-drive"
                      role="tabpanel"
                      aria-labelledby="nav-drive-tab"
                    >
                      <form action="#" className="form1">
                        <h2 className="form-title">
                          {" "}
                          Start driving a carpool and earn instantly{" "}
                        </h2>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              id="firstName2"
                              placeholder="First Name"
                              disabled
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              id="lastName2"
                              placeholder="Last Name"
                              disabled
                            />
                          </div>
                          <div className="form-group col-12">
                            <input
                              type="number"
                              className="form-control"
                              name="number"
                              id="number2"
                              placeholder="Phone Number"
                              disabled
                            />
                          </div>
                          <div className="form-group col-12">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email2"
                              placeholder="Email Address"
                              disabled
                            />
                          </div>
                          <div className="form-group col-12">
                            <input
                              type="text"
                              className="form-control"
                              name="location"
                              id="location2"
                              placeholder="City"
                              disabled
                            />
                          </div>
                          <div className="form-group col-12">
                            <input
                              className="form-control"
                              name="password"
                              id="password"
                              placeholder="Password"
                              type={passwordInputType}
                              disabled
                            />
                            <span
                              id="toggle-password"
                              className="toggle-password"
                              onClick={togglePasswordVisibility}
                              style={{ cursor: "pointer" }}
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="col-12 form-group mt-3">
                            <label for="agree2">
                              By proceeding, you agree to the{" "}
                              <a href="/terms-of-use">Terms and Conditions</a>{" "}
                              and <a href="/privacy-policy">Privacy Policy</a>
                            </label>
                          </div>
                          <div className="form-btn col-12">
                            <a href="https://forms.gle/ZSHEKzHcbfxtA2aNA">
                              <button
                                className="form-button button button-dark big"
                                style={{
                                  background: "#962A8D",
                                  color: "#fff",
                                  marginTop: "30px",
                                }}
                              >
                                {" "}
                                {/* Become a Driver{" "} */}
                                Join Waitlist
                              </button>
                            </a>
                          </div>
                        </div>
                        <p className="form-messages mb-0 mt-3"></p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Herocta;
