import React, { useState, useCallback } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../ContactForm/contactform.css";
import { FaCheck } from "react-icons/fa6";

const countryPhoneCodes = {
  Kenya: "+254 ",
  Rwanda: "+250 ",
  USA: "+1 ",
  Canada: "+1 ",
  Ghana: "+233 ",
  Nigeria: "+234 ",
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    phone: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;

    if (name === "country") {
      const countryCode = countryPhoneCodes[value] || "";
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        phone: countryCode,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  }, []);

  const handlePhoneChange = (e) => {
    let phoneValue = e.target.value;

    if (!phoneValue.startsWith("+")) {
      phoneValue = "+" + phoneValue;
    }

    phoneValue = phoneValue.replace(/(?!^\+)[^\d\s-]/g, "");

    setFormData((prevData) => ({ ...prevData, phone: phoneValue }));
  };

  const handleCheckboxChange = useCallback(
    async (e) => {
      const checked = e.target.checked;
      setIsChecked(checked);

      if (checked) {
        try {
          await axios.post(
            process.env.NODE_ENV === "production"
              ? "https://triphoppa-web-app-451eed876a4d.herokuapp.com/api/v1/newsletter/subscribe"
              : "http://localhost:3000/api/v1/newsletter/subscribe",
            {
              email: formData.email,
            }
          );
        } catch (error) {
          if (error.response && error.response.status === 400) {
          } else {
            console.error("Subscription error:", error);
          }
        }
      }
    },
    [formData.email]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSending(true);

      try {
        await axios.post(
          process.env.NODE_ENV === "production"
            ? "https://triphoppa-web-app-451eed876a4d.herokuapp.com/api/v1/contact/contact"
            : "http://localhost:3000/api/v1/contact/contact",
          formData
        );
        toast.success("Email sent successfully!", {
          position: "top-right",
          icon: (
            <span
              style={{
                backgroundColor: "#96298d",
                color: "white",
                borderRadius: "50%",
                padding: "2px 4px",
              }}
            >
              <FaCheck />
            </span>
          ),
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          progressStyle: { backgroundColor: "#96298d" },
        });

        setFormData({
          name: "",
          email: "",
          country: "",
          phone: "",
          message: "",
        });
        setIsChecked(false);
      } catch (error) {
        console.error("Error submitting form", error);
        toast.error("Error sending email. Please try again later.", {
          position: "top-right",
          icon: (
            <span
              style={{
                backgroundColor: "#96298d",
                color: "white",
                borderRadius: "50%",
                padding: "2px 4px",
              }}
            >
              <FaCheck />
            </span>
          ),
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          progressStyle: { backgroundColor: "#96298d" },
        });
      } finally {
        setIsSending(false);
      }
    },
    [formData]
  );

  return (
    <>
      <section
        style={{ marginBottom: "2rem" }}
        className="contact_page_section"
      >
        <div className="container">
          <div className="contact_inner row">
            <div className="contact_form col-lg-6 col-md-12">
              <div className="section_title">
                <h2>
                  Leave a <span>message</span>
                </h2>
                <p>Fill up form below, our team will get back to you soon!</p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="form-control"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="form-control"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="country"
                    placeholder="Country"
                    className="form-control"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                    className="form-control"
                    value={formData.phone}
                    onChange={handlePhoneChange}
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Your message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="form-group term_check">
                  <input
                    type="checkbox"
                    id="term"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="term">
                    I agree to receive emails, newsletters and promotional
                    messages
                  </label>
                </div>
                <div className="form-group mb-0">
                  <button
                    disabled={isSending}
                    type="submit"
                    className="btn puprple_btn"
                  >
                    {isSending ? "Sending..." : "SEND MESSAGE"}
                  </button>
                </div>
              </form>
            </div>
            <div className="contact_info col-lg-6 col-md-12">
              <div className="icon">
                <img src="enc/images/contact_message_icon.png" alt="img" />
              </div>
              <div className="section_title">
                <h2>
                  Have any <span>question?</span>
                </h2>
                <p>
                  If you have any question about our product, service, payment
                  or company.
                </p>
              </div>
              <a href="/#faq" className="btn puprple_btn">
                READ FAQ
              </a>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img src="enc/images/mail_icon.png" alt="img" />
                  </div>
                  <div className="text">
                    <span>Email Us</span>
                    <a href="mailto:support@triphoppa.com">
                      support@triphoppa.com
                    </a>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src="enc/images/call_icon.png" alt="img" />
                  </div>
                  <div className="text">
                    <span>Call Us</span>
                    <a href="tel:+254746389580">+254 (746) 389-580</a>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src="enc/images/location_icon.png" alt="img" />
                  </div>
                  <div className="text">
                    <span>Visit Us</span>
                    <p>00100, Business Avenue, Nairobi, Kenya</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </section>
    </>
  );
};

export default ContactForm;
