import React from "react";
import Navbartwo from "../../components/Getstartedpage/Header/Header";
import Herocta from "../../components/Getstartedpage/Hero/Hero";
import AppDownload from "../../components/Getstartedpage/AppDownload/AppDownload";
import FooterTwo from "../../components/Getstartedpage/FooterTwo/FooterTwo";

const Starter = () => {
  return (
    <div className="theme-1">
      <Navbartwo />
      <Herocta />
      <AppDownload />
      <FooterTwo />
    </div>
  );
};

export default Starter;
