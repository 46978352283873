import React from "react";
import { useLocation } from "react-router-dom";
import "../BreadCrumbs/breadcrumbs.css";

const BreadCrumbs = () => {
  const location = useLocation();

  const breadcrumbMap = {
    "/contact-us": "Contact Us",
    "/privacy-policy": "Privacy Policy",
    "/terms-of-use": "Terms Of Use",
    // Add other paths as needed
  };

  const currentPath = location.pathname;

  const breadcrumbLabel = breadcrumbMap[currentPath];

  return (
    // <!-- BredCrumb-Section -->
    <div className="bred_crumb">
      <div className="container">
        <div className="bred_text">
          <h1 className="breadcrumb-item active" aria-current="page">
            {breadcrumbLabel}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumbs;
