import React from "react";
import "../Animline/anim.css";

const Animline = () => {
  return (
    <div className="anim_line">
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
      <span>
        <img src="/enc/images/anim_line.png" alt="anim_line" />
      </span>
    </div>
  );
};

export default Animline;
