import React from "react";
import BreadCrumbs from "../../components/More/BreadCrumbs/BreadCrumbs";
import Footer from "../../components/Landingpage/Footer/Footer";
import Navbar from "../../components/Landingpage/Navbar/Navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <BreadCrumbs />
      <div className="container" id="privacy_policy">
        <div className="mt-4">
          <h1>TripHoppa Carpooling App - Data Privacy Policy</h1>
          <p>
            At TripHoppa, we value your privacy and are committed to protecting
            your personal information. This Privacy Policy explains how we
            collect, use, and protect your data when you use our TripHoppa
            carpooling app. This policy applies to all users, including both
            passengers and drivers. To access specific services provided by the
            Platform, you are required to create an account and become a Member.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            We collect personal information that helps us provide a safe and
            efficient carpooling service. The types of information we may
            collect include:
          </p>
          <ul>
            <li>
              <strong>Account Information:</strong> When you create an account,
              we collect details like your first and last name, email address,
              phone number, address, and password.
            </li>
            <li>
              <strong>Personal Details:</strong>
              <ul>
                <li>
                  <strong>Passengers:</strong> A valid government-issued ID
                  (such as a passport or national ID card) to prove identity.
                </li>
                <li>
                  <strong>Drivers:</strong>
                  <ul>
                    <li>
                      <strong>Driver’s License:</strong> Proof of a valid
                      driver’s license to verify you are legally allowed to
                      drive.
                    </li>
                    <li>
                      <strong>Vehicle Information:</strong> Details about the
                      vehicle, such as the make, model, year, and registration
                      number.
                    </li>
                    <li>
                      <strong>Insurance:</strong> Proof of valid insurance of
                      the vehicle.
                    </li>
                    <li>
                      <strong>Identity Verification Photos:</strong> Such as
                      passports (which may contain identification photos, date
                      of birth, and gender).
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <strong>Location Data:</strong> Your real-time location when using
              the app for ride-matching (to detect your current location and
              match you with drivers and passengers nearby for pickup).
            </li>
            <li>
              <strong>Ride Preferences:</strong> Pickup and drop-off locations,
              carpooling schedule (times and days), and other preferences
              related to rides (e.g., a quiet ride).
            </li>
            <li>
              <strong>Usage Data:</strong> Information on how you use the app,
              including app interaction data, device information, and IP
              address.
            </li>
            <li>
              <strong>Payment Information:</strong> The authorized means of
              payment are as follows: (e.g., mobile money, M-Pesa, PayPal, or
              card details).
            </li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul className="how_to_use_info">
            <li>
              To match you with suitable carpooling partners based on your
              location and ride preferences.
            </li>
            <li>
              To ensure the safety of users through identity verification and
              ride tracking.
            </li>
            <li>
              To communicate with you about ride status, updates, or important
              notifications.
            </li>
            <li>To process payments and manage any refunds.</li>
            <li>To improve our services and customize your app experience.</li>
            <li>To comply with legal and regulatory requirements.</li>
          </ul>

          <h2>3. Sharing Your Information</h2>
          <p>
            We do not sell or rent your personal information to third parties.
            However, we may share your data with:
          </p>
          <ul>
            <li>
              <strong>Carpooling Partners:</strong> Other users (passengers and
              drivers) you are matched with for a ride, including your name,
              phone number, and location for coordination purposes.
            </li>
            <li>
              <strong>Service Providers:</strong> Trusted third-party partners
              that help us operate the app, such as payment processors and cloud
              storage providers.
            </li>
            <li>
              <strong>Legal Authorities:</strong> If required by law, we may
              share your information with legal or regulatory authorities to
              comply with legal obligations or respond to valid legal requests.
            </li>
          </ul>

          <h2>4. Your Data Rights</h2>
          <p>As a user of TripHoppa, you have the following rights:</p>
          <ul>
            <li>
              <strong>Access:</strong> You can request access to the personal
              data we hold about you.
            </li>
            <li>
              <strong>Correction:</strong> You can update or correct any
              inaccurate information.
            </li>
            <li>
              <strong>Data Deletion:</strong> You can delete your personal data
              at any time without cost or reason if you feel your information is
              incomplete or misleading. Please refer to the steps under “Data
              Retention” on how to delete your data.
            </li>
            <li>
              <strong>Data Portability:</strong> You can request a copy of your
              data in a portable format.
            </li>
            <li>
              <strong>Withdraw Consent:</strong> You can withdraw your consent
              to data processing at any time.
            </li>
          </ul>
          <p>
            To exercise your rights, please fill out the appropriate form in the
            app.
          </p>

          <h2>5. Data Security</h2>
          <p>
            We take data security seriously and use industry-standard measures
            to protect your information. This includes encryption, secure
            servers, and access control mechanisms. While we do our best to
            safeguard your data, no system is completely secure, and we cannot
            guarantee the security of your information.
          </p>

          <h2>6. Data Retention</h2>
          <p>
            We retain your data for as long as necessary to provide our
            services, comply with legal obligations, resolve disputes, and
            enforce our agreements. Once your data is no longer needed, we
            securely delete it.
          </p>
          <p>
            Please follow these steps to close your TripHoppa account and delete
            all associated data through the TripHoppa app:
          </p>
          <ol>
            <li>Download or open the TripHoppa app.</li>
            <li>Sign in to your account.</li>
            <li>
              Select the "Profile" bottom navigation tab in the bottom right
              corner, then select the "Account" tab at the top.
            </li>
            <li>
              Click on "Close Account," then confirm by clicking "Delete
              account."
            </li>
          </ol>
          <p>
            In case you need additional help or have a specific request, fill
            out the appropriate form or contact us. Our support team will
            contact you to verify your identity before fulfilling your request.
          </p>

          <h2>7. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and you will be notified via email or
            through the app if significant changes are made. Please review the
            policy periodically to stay informed.
          </p>

          <h2>8. How to Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or requests
            regarding your personal data, please contact us at:
          </p>
          <ul>
            <li>Email: support@triphoppa.com</li>
            <li>Phone: Kenya: +254 746 389 580</li>
            <li>Address: 1014 Panet St, Ottawa, ON K1Z 6E9, Canada</li>
            <li>
              Website: <a href="/">www.triphoppa.com</a>
            </li>
          </ul>
          <p>
            Our contact details are also available in the app under the Help &
            Support section.
          </p>

          <p>Effective Date: [Insert Effective Date]</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
