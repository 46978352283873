import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Header/header.css";
import Responsive from "./HeaderMobile";

import {
  faHome,
  faMapMarkerAlt,
  faTaxi,
  faUser,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";

const Navbartwo = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Sticky header
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 250) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="theme-1" id="header">
      <div className={`header__upper ${isSticky ? "sticky" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="header__upper--left">
                <div className="d-none d-lg-block logo">
                  <a href="/">
                    <img
                      style={{ maxWidth: "54%" }}
                      src="/enc/images/logo_3.png"
                      alt="TripHoppa Logo"
                    />
                  </a>
                </div>
                <div className="d-block d-lg-none logo w-49px">
                  <a href="/">
                    <img
                      src="/enc/images/logo_3.png"
                      alt="TripHoppa Logo"
                      width="180"
                      height="80"
                    />
                  </a>
                </div>

                <button
                  type="button"
                  className="nav-toggle-btn a-nav-toggle ms-auto d-block d-lg-none"
                  aria-label="toggle-nav"
                  onClick={toggleMenu}
                >
                  <span className="nav-toggle nav-toggle-sm">
                    <span className="stick stick-1"></span>
                    <span className="stick stick-2"></span>
                    <span className="stick stick-3"></span>
                  </span>
                </button>
              </div>
            </div>
            <div className="d-none d-lg-block col-lg-6">
              <div className="header__upper--right">
                <nav className="navigation">
                  <ul>
                    <li>
                      <a href="/">
                        <FontAwesomeIcon icon={faUser} /> Dashboard
                      </a>
                    </li>
                  </ul>
                </nav>
                <a href="/" className="button p-0">
                  <FontAwesomeIcon className="icon" icon={faUserAstronaut} />{" "}
                  Drive with us{" "}
                </a>
                <a href="/" className="button p-0">
                  <FontAwesomeIcon className="icon" icon={faTaxi} /> Book a Ride{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header__lower">
        <div className="container">
          <div className="row">
            <div className="d-none d-lg-block col-lg-12">
              <nav className="navbar navbar-expand-lg navbar-dark">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item active">
                      <a className="nav-link" href="/">
                        <FontAwesomeIcon icon={faHome} /> Home{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/contact-us">
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> Contact Us{" "}
                      </a>
                    </li>
                  </ul>
                  <div className="my-2 my-lg-0 d-inline-flex">
                    <a
                      href="/get-started"
                      className="button button-light big"
                      style={{ background: "#96298d", color: "#fff" }}
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <Responsive menuOpen={menuOpen} toggleMenu={toggleMenu} />
    </header>
  );
};

export default Navbartwo;
