import React from "react";
import Navbar from "../../components/Landingpage/Navbar/Navbar";
import BreadCrumbs from "../../components/More/BreadCrumbs/BreadCrumbs";
import ContactForm from "../../components/More/ContactForm/ContactForm";
import Footer from "../../components/Landingpage/Footer/Footer";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <BreadCrumbs />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default Contact;
