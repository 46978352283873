import React from "react";
import "../Loader/preloader.css";

const Preloader = () => {
  return (
    // <!-- Preloader -->
    <div id="preloader">
      <div id="loader"></div>
    </div>
  );
};

export default Preloader;
