import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import Preloader from "../../More/Loader/Preloader";
import "../Navbar/navbar.css";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isIconClose, setIsIconClose] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  // Toggle navbar open/close
  const handleNavbarToggle = () => {
    setIsNavOpen(!isNavOpen);
    setIsIconClose(!isIconClose);
  };

  // Smooth scroll to section
  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    // Close the navbar after scrolling
    setIsNavOpen(false);
    setIsIconClose(false);
  };

  // Show loader when navigating to another page
  const handleNavClick = (e, path) => {
    if (path.startsWith("#")) {
      e.preventDefault();
      // Navigate to home and scroll to the section if not on homepage
      if (location.pathname !== "/") {
        window.location.href = `/#${path.substring(1)}`;
      } else {
        scrollToSection(path);
      }
    } else {
      setShowLoader(true);
    }
  };

  useEffect(() => {
    setShowLoader(false);
  }, [location]);

  // Sticky header
  const [isSticky, setIsSticky] = useState(false);
  const handleScroll = () => {
    setIsSticky(window.scrollY >= 250);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDropdownToggle = useCallback(
    () => setDropdownOpen((prev) => !prev),
    []
  );

  const handleMouseLeave = useCallback((e) => {
    if (
      dropdownRef.current &&
      e.relatedTarget &&
      !dropdownRef.current.contains(e.relatedTarget)
    ) {
      setDropdownOpen(false);
    }
  }, []);

  return (
    <>
      {showLoader && <Preloader />}
      <header className={`${isSticky ? "fix_style fixed" : ""}`}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img
                src="/enc/images/logo_3.png"
                alt="logo"
                width="180"
                height="80"
              />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              aria-expanded={isNavOpen}
              onClick={handleNavbarToggle}
            >
              <div className="toggle-wrap">
                <span className="ico_menu">
                  {isIconClose ? (
                    <AiOutlineClose size={30} />
                  ) : (
                    <AiOutlineMenu size={30} />
                  )}
                </span>
              </div>
            </button>

            <div
              className={`nav-collapse navbar-collapse ${
                isNavOpen ? "show" : ""
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="#home"
                    onClick={(e) => handleNavClick(e, "#home")}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="#features"
                    onClick={(e) => handleNavClick(e, "#features")}
                  >
                    Features
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="#how_it_work"
                    onClick={(e) => handleNavClick(e, "#how_it_work")}
                  >
                    How it works
                  </Link>
                </li>

                <li
                  className="nav-item dropdown"
                  onMouseEnter={() => setDropdownOpen(true)}
                  onMouseLeave={handleMouseLeave}
                  ref={dropdownRef}
                >
                  <button
                    className="nav-link dropdown-toggle"
                    aria-expanded={isDropdownOpen}
                    onClick={handleDropdownToggle}
                  >
                    More
                  </button>
                  <ul
                    className={`dropdown-menu dropdown-menu-end ${
                      isDropdownOpen ? "show" : ""
                    }`}
                  >
                    <li>
                      <a className="dropdown-item" href="/contact-us">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/privacy-policy">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/terms-of-use">
                        Terms Of Use
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link dark_btn" href="/get-started">
                    GET STARTED
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navbar;
