import React, { useState, useEffect } from "react";
import "../Features/features.css";

const Features = () => {
  const [showFullText, setShowFullText] = useState({
    booking: false,
    secure: false,
    support: false,
    verified: false,
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Detect screen width and update `isMobile` on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleText = (section) => {
    setShowFullText((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const truncateText = (text, section) => {
    // Only truncate if it's not mobile and full text is not shown
    if (!isMobile && !showFullText[section]) {
      return text.length > 100 ? text.slice(0, 100) + "..." : text;
    }
    return text;
  };

  return (
    <section className="row_am features_section" id="features">
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="100"
        >
          <h2>
            What <span>TripHoppa</span> offers!
          </h2>
          <p>
            TripHoppa connects people for shared rides, giving you a smarter way
            to travel. <br />
            From everyday commutes to long-distance journeys, we make
            ride-sharing simple, affordable, and sustainable.
          </p>
        </div>

        <div className="feature_detail">
          {/* Feature box left */}
          <div className="left_data feature_box">
            <div
              className="data_block"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="icon">
                <img
                  width={"120px"}
                  src="/enc/images/easy_booking.png"
                  alt="img"
                />
              </div>
              <div className="text">
                <h4>Easy Booking</h4>
                <p>
                  {truncateText(
                    "With TripHoppa, booking a ride is quick and effortless. Just enter the trip details and secure your seat all in a matter of seconds.",
                    "booking"
                  )}
                  {!isMobile && (
                    <span
                      onClick={() => toggleText("booking")}
                      className="read-more-link"
                    >
                      {showFullText.booking ? " See Less" : " See All"}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div
              className="data_block"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="icon">
                <img src="/enc/images/secure_data.png" alt="img" />
              </div>
              <div className="text">
                <h4>Secure Payments and Data</h4>
                <p>
                  {truncateText(
                    "Enjoy secure payments through mobile money, making easy, safe, and hassle-free transactions. Your financial information is protected, allowing you to focus on your journey. Additionally, we prioritize your privacy ensuring your personal data is protected during every trip.",
                    "secure"
                  )}
                  {!isMobile && (
                    <span
                      onClick={() => toggleText("secure")}
                      className="read-more-link"
                    >
                      {showFullText.secure ? " See Less" : " See All"}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* Feature box right */}
          <div className="right_data feature_box">
            <div
              className="data_block"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="icon">
                <img src="/enc/images/support.png" alt="img" />
              </div>
              <div className="text">
                <h4>24/7 Customer Support</h4>
                <p>
                  {truncateText(
                    "Our team is available around the clock to assist you with any questions or issues, ensuring you have reliable support whenever you need it.",
                    "support"
                  )}
                  {!isMobile && (
                    <span
                      onClick={() => toggleText("support")}
                      className="read-more-link"
                    >
                      {showFullText.support ? " See Less" : " See All"}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div
              className="data_block"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="icon">
                <img width={"120px"} src="/enc/images/verified.png" alt="img" />
              </div>
              <div className="text">
                <h4>Verified Users & Safety</h4>
                <p>
                  {truncateText(
                    "At TripHoppa, your safety is our top priority. We guarantee that personal information is shared only with verified users who have either confirmed a booking or subscribed to our service. This verification system fosters a trustworthy community of riders and drivers, ensuring you feel secure on every trip.",
                    "verified"
                  )}
                  {!isMobile && (
                    <span
                      onClick={() => toggleText("verified")}
                      className="read-more-link"
                    >
                      {showFullText.verified ? " See Less" : " See All"}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          {/* Feature image */}
          <div
            className="feature_img"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="100"
          >
            <img
              src="/enc/images/mockups/3-3.png"
              className="w-[20rem]"
              alt="img"
              style={{ width: 20 + "rem" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
