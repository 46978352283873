import React from "react";
import Navbar from "../../components/Landingpage/Navbar/Navbar";
import Footer from "../../components/Landingpage/Footer/Footer";
import BreadCrumbs from "../../components/More/BreadCrumbs/BreadCrumbs";

const TermsOfUse = () => {
  return (
    <>
      <Navbar />
      <BreadCrumbs />
      <div className="container" id="terms_of_use">
        <div className="mt-4">
          <h1>TripHoppa Carpooling App - Terms of Use</h1>
          <p>
            Welcome to TripHoppa, a Carpooling Platform designed for
            ride-sharing. By using the TripHoppa app, you agree to the following
            terms and conditions. These terms ensure a safe, transparent, and
            enjoyable carpooling experience for all users. Please read
            carefully.
          </p>

          <h2>Acceptance of Terms</h2>
          <p>
            By registering on the platform, you must have read and accepted
            these <a href="/termsofuse">Terms and Conditions</a> and the{" "}
            <a href="/privacypolicy">Privacy Policy</a>.
          </p>

          <h2>User Accounts</h2>
          <ul>
            <li>
              You are responsible for keeping your login information secure.
            </li>
            <li>
              You must provide accurate and current information when
              registering.
            </li>
            <li>
              TripHoppa reserves the right to suspend or terminate accounts that
              provide false information.
            </li>
          </ul>

          <h2>Ride-Sharing</h2>
          <p>
            TripHoppa connects drivers and passengers for carpooling but doesn't
            own or operate vehicles. Both drivers and passengers must agree on
            the details of the ride (time, location, cost-sharing, etc.) before
            the trip.
          </p>

          <h2>Drivers’ Obligations</h2>
          <ul>
            <li>Drivers must have a valid driver's license and insurance.</li>
            <li>
              Drivers are responsible for ensuring their vehicles are safe,
              roadworthy, and comply with all laws.
            </li>
            <li>
              Drivers must not use their trip for any fraudulent, unlawful, or
              criminal activity.
            </li>
            <li>
              Drivers should agree on a reasonable cost-sharing with passengers
              based on distance traveled.
            </li>
            <li>
              Drivers should arrive at the agreed location with the specified
              vehicle.
            </li>
            <li>
              Drivers should inform their passengers with immediate effect of
              any changes to the trip.
            </li>
            <li>
              Drivers must wait for their passengers at the pick-up point for at
              least 10 minutes past the agreed time, in case the passenger
              experiences unforeseen circumstances. However, the passenger is
              expected to be on time.
            </li>
          </ul>

          <h2>Passengers’ Obligations</h2>
          <ul>
            <li>They must respect the driver's vehicle and property.</li>
            <li>
              They should confirm trip details with the driver and arrive on
              time.
            </li>
            <li>
              They must contribute their agreed-upon share of the cost promptly.
            </li>
            <li>
              They must not use their trip for any fraudulent, unlawful, or
              criminal activity.
            </li>
            <li>
              They should quickly inform the driver if they need to cancel the
              trip.
            </li>
            <li>
              They should remain at the pickup station for at least 10 minutes
              after the scheduled arrival time, in case the driver experiences
              unforeseen circumstances.
            </li>
          </ul>

          <h2>User Conduct</h2>
          <ul>
            <li>Users must treat each other with respect.</li>
            <li>
              Harassment, bullying, discriminatory behavior, or abusive language
              is strictly prohibited. This will result in the suspension or
              termination of your account.
            </li>
            <li>
              Users must not engage in any criminal activities while using
              TripHoppa. In any case, it should be reported to authorities
              immediately.
            </li>
          </ul>

          <h2>Liability</h2>
          <ul>
            <li>
              TripHoppa is a platform, for connecting users and doesn't take
              responsibility for the actions of drivers and passengers.
            </li>
            <li>
              It is not liable for accidents, injuries, or disputes arising from
              rides.
            </li>
            <li>
              Both drivers and passengers must ensure they have the appropriate
              insurance coverage for their activities.
            </li>
          </ul>

          <h2>Privacy</h2>
          <p>
            We value your privacy. Your personal information is protected under
            our Privacy Policy, which can be found on our website.
          </p>

          <h2>Changes to Terms</h2>
          <p>
            TripHoppa reserves the right to update these terms of use at any
            time. You will be notified of changes, Users will be notified of
            changes, and continued use of the app after such changes means you
            accept the updated terms. Users may be required to clearly agree to
            the updated terms by clicking “I agree”.
          </p>

          <h2>Termination</h2>
          <p>
            TripHoppa reserves the right to suspend or terminate accounts for
            violation of these terms.
          </p>

          <h2>Dispute Resolution</h2>
          <p>
            In the case of disputes, users are encouraged to resolve issues
            between themselves.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions regarding these terms and conditions,
            please contact us at <span>support@triphoppa.com</span>.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
