import React from "react";
import "../AppDownload/appdownload.css";

const AppDownload = () => {
  return (
    <section
      className="download-div div-padding"
      style={{ paddingBottom: "0" }}
    >
      <div className="container">
        <div className="download-content-wrapper">
          <div className="download-left-content">
            <h2 className="div-title">Download Trip Hoppa</h2>
            <h3>Download the Trip Hoppa mobile application</h3>
            <div className="app-btns download-buttons">
              <a
                href="https://testflight.apple.com/join/X7E3qz6R"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="download-apple-btn"
              >
                <img src="/assets/images/download-1.webp" alt="img" />
              </a>
              <a
                href="https://play.google.com/apps/internaltest/4701682408872991350"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="download-android-btn"
              >
                <img src="/assets/images/download-2.webp" alt="img" />
              </a>
            </div>
          </div>
          <div className="download-right-content">
            <img
              className="download-blob"
              src="/assets/images/purple-blob.svg"
              alt="Yellow blob"
            />
            <img
              className="download-phone-img"
              src="/enc/images/mockups/1.png"
              style={{ width: "25rem" }}
              alt="Mobile phone"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDownload;
