import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import Home from "./pages/Homepage/Home";
import Starter from "./pages/Getstarted/Getstarted";
import Contact from "./pages/Contactpage/Contact";
import PrivacyPolicy from "./pages/Privacypolicy/PrivacyPolicy";
import TermsOfUse from "./pages/Termsofusepage/TermsOfUse";
import Preloader from "./components/More/Loader/Preloader";
import { logPageView } from "./index";

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};

const Main = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    logPageView();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    if (!loading) {
      if (location.hash) {
        const sectionID = location.hash.substring(1);
        const sectionElement = document.getElementById(sectionID);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [loading, location]);

  return (
    <>
      {loading && <Preloader />}
      <div style={{ visibility: loading ? "hidden" : "visible" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/get-started" element={<Starter />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
