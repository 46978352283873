import React from "react";
import "../Modern/modern.css";
import "../Animline/anim.css";

const Modern = () => {
  return (
    // <!-- ModernUI-Section-Start -->
    <section className="row_am modern_ui_section">
      {/* <!-- container start --> */}
      <div className="container">
        {/* <!-- row start --> */}
        <div className="row">
          <div className="col-lg-6">
            {/* <!-- UI content --> */}
            <div className="ui_text">
              <div
                className="section_title"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="100"
              >
                <h2>
                  Sleek, <span>Modern Design</span>
                </h2>
                <p>
                  TripHoppa offers a sleek, intuitive interface that enhances
                  your user experience, making ride-sharing effortless and
                  visually appealing.
                </p>
              </div>
              <ul className="design_block">
                <li data-aos="fade-up" data-aos-duration="1500">
                  <h4>Carefully designed</h4>
                  <p>
                    Every feature on TripHoppa has been thoughtfully crafted to
                    provide a seamless, user-centric experience that makes
                    ride-sharing easy and efficient.
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration="1500">
                  <h4>Seamless Sync</h4>
                  <p>
                    TripHoppa syncs effortlessly across all your devices,
                    ensuring your ride details and preferences are always
                    up-to-date, no matter where you are.
                  </p>
                </li>
                <li data-aos="fade-up" data-aos-duration="1500">
                  <h4>Trip Management</h4>
                  <p>
                    With TripHoppa, accessing and managing your rides is just a
                    tap away. Stay in control of your trips, routes, and
                    schedules effortlessly.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            {/* <!-- UI Image --> */}
            <div
              className="ui_images"
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="left_img">
                <img
                  className="moving_position_animatin"
                  src="/enc/images/mockups/component3.png"
                  alt="img"
                />
              </div>
              {/* <!-- UI Image --> */}
              <div className="right_img">
                <img
                  className="moving_position_animatin"
                  src="/enc/images/secure_data.png"
                  alt="img"
                />
                <img
                  className="moving_position_animatin"
                  src="/enc/images/mockups/component.png"
                  alt="img"
                />
                <img
                  className="moving_position_animatin"
                  src="/enc/images/mockups/component2.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- row end --> */}
      </div>
      {/* <!-- container end --> */}
    </section>
  );
};

export default Modern;
