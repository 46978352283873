import React from "react";
import "../FooterTwo/footertwo.css";
import {
  FaPinterestP,
  FaLinkedin,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaGooglePlusG,
} from "react-icons/fa";

const FooterTwo = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer class="footer-div theme-1" id="footer">
      <div class="footer-shape">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <div class="footer-nav-div div-padding theme-1">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="footer-brand">
                <a href="/">
                  <img
                    src="/enc/images/logo_3.png"
                    alt="Logo"
                    style={{ width: "16rem" }}
                  />
                </a>
              </div>
              <div class="footer-text pe-lg-3"></div>
              <div class="helpline">
                <h3 class="mb-0">
                  Toll Free <span>Helpline</span>
                </h3>
                <p class="mb-0">
                  <a href="tel:+254746389580">+254 746 389-580</a>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <h4>Useful links</h4>
              <nav class="footer-navigation">
                <ul>
                  <li>
                    <a href="/terms-of-use">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/">Cookie Policy</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-lg-4 col-sm-6">
              <h4>Download Mobile App</h4>
              <div class="app-download-box">
                <a
                  href="https://play.google.com/apps/internaltest/4701682408872991350"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/google-play.webp"
                    alt="Google play"
                  />
                </a>
                <a
                  href="https://testflight.apple.com/join/X7E3qz6R"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/apple-store.webp"
                    alt="Apple store"
                  />
                </a>
              </div>
              <div class="cta-button">
                <a
                  href="/get-started"
                  class="button button-light big"
                  style={{ backGround: "#962A8D", color: "#fff" }}
                >
                  Become a Driver
                </a>
                <a href="/get-started" class="button button-black big">
                  Ride with TripHoppa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-div">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <p>
                &copy; Copyright {currentYear}. TripHoppa. All Rights Reserved.
              </p>
            </div>
            <div class="col-lg-6">
              <ul class="social-nav">
                <li>
                  <a
                    href="www.facebook.com/triphoppa"
                    class="facebook"
                    aria-label="facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF size={20} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://x.com/TRIPHOPPA1"
                    class="twitter"
                    aria-label="twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter size={20} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/triphoppa"
                    class="instagram"
                    aria-label="instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram size={20} />
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    class="google-p"
                    aria-label="google"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGooglePlusG size={20} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/triphoppa/posts/?feedView=all"
                    class="linkedin"
                    aria-label="linkedin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin size={20} />
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    class="pinterest"
                    aria-label="pinterest"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaPinterestP size={20} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;
