import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Header/header.css";
import {
  faHome,
  faMapMarkerAlt,
  faTaxi,
  faTimes,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";

const Responsive = ({ menuOpen, toggleMenu }) => {
  return (
    <section className={`collapse responsive-menu ${menuOpen ? "open" : ""}`}>
      <div className="rep-header">
        <div className="logo">
          <a href="/">
            <img
              src="/enc/images/logo_3.png"
              width="180"
              height="80"
              alt="TripHoppa Logo"
            />
          </a>
        </div>
        <button
          type="button"
          title="Close Menu"
          className="close-menu"
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="navbar-menu" id="navbarSupportedContent1">
        <ul className="mobile-menu navbar-nav me-auto">
          <li className="nav-item active">
            <a className="nav-link" href="/">
              <FontAwesomeIcon icon={faHome} /> Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/contact-us">
              <FontAwesomeIcon icon={faMapMarkerAlt} /> Contact Us{" "}
            </a>
          </li>
        </ul>
        <div className="header__upper--right flex-column">
          <nav className="navigation p-3">
            <ul>
              <li>
                <a href="/">Driver Dashboard</a>
              </li>
            </ul>
          </nav>
          <a href="/" className="button p-3">
            <FontAwesomeIcon icon={faUserAstronaut} className="icon" /> Drive
            with us{" "}
          </a>
          <a href="/" className="button p-3 my-2">
            <FontAwesomeIcon icon={faTaxi} className="icon" /> Book a Ride
          </a>
          <div className="p-3 my-lg-0 d-inline-flex responsive">
            <a href="/" className="button button-light big">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Responsive;
